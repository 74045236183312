.home {
    text-align: center;
    margin-top: 10%;

    h1 {
        font-size: 4rem;
    }

    ul {
        li {
            font-size: 1.5rem;
        }
    }
}
