.manual {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    height: 100%;
}

.rawControls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    max-width: 400px;
    height: 300px;
    margin: 0 auto;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p {
        text-align: center;
        margin: 0;
        color: var(--color-neutral-800);
        font-size: 0.9rem;
    }

    button {
        font-size: 4rem;
        display: grid;
        place-items: center;
        width: 3rem;
        height: 3rem;

        svg {
            position: relative;
            top: -0.7rem;
            left: -0.95rem;
        }
    }
}

.sliderControls {
    width: 60%;
    min-width: 400px;
    margin: 0 auto;

    > div {
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
            margin-right: 1rem;
        }

        input {
            flex-grow: 2;
        }

        p {
            margin-left: 1rem;
        }
    }
}

.svgUp {
    top: -0.7rem !important;
    left: -0.9rem !important;
}
.svgDown {
    top: -0.5rem !important;
}
.playStop {
    font-size: 3rem !important;

    svg {
        top: -0.25rem !important;
        left: -0.5rem !important;
    }
}

.randomize {
    margin-top: 2rem;

    h2 {
        margin-bottom: 0.5rem;
    }
}

.randomizationControl {
    display: flex;
    align-items: center;
    height: 2.5rem;

    label {
        width: 12rem;
    }

    input[type="checkbox"] {
        transform: scale(2);
    }

    input[type="range"] {
        flex-grow: 2;
        margin-right: 1rem;
    }

    p {
        width: 4rem;
    }

    p.description {
        flex-grow: 2;
        margin-left: 1rem;
        font-style: italic;
        color: var(--color-neutral-800);
    }
}

.randomizationSummary {
    height: 4rem;
    text-align: center;

    p {
        margin: 0.5rem 0;
        text-align: center;
        color: var(--color-neutral-800);
        font-style: italic;
    }

    li {
        margin: 0.5rem 0;
        color: var(--color-neutral-800);
        font-style: italic;
    }
}
