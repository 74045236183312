.sidebar {
    width: 220px;
    height: calc(100vh - 40px);
    background-color: var(--color-neutral-300);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebarNav {
    width: 100%;

    border-bottom: 1px solid var(--color-neutral-500);

    > li {
        min-height: 3rem;

        display: flex;
        justify-content: space-between;
    }

    a {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        margin: 0;
    }

    a {
        background-color: var(--color-neutral-300);

        transition: all 0.3s;

        &:hover {
            background-color: var(--color-neutral-400);
        }
    }
}

.sidebarNavFooter {
    border-top: 1px solid var(--color-neutral-500);
    margin-bottom: 1rem;
}

.selected a {
    background-color: var(--color-neutral-200);
    overflow: hidden;
    position: relative;

    &:before {
        content: "\0020";
        display: block;
        position: absolute;
        width: calc(100% + 40px);
        height: 20px;
        top: -20px;
        left: -20px;
        box-shadow: 0px 0px 10px 0px black;
    }
}

.navIcon {
    width: 1.5rem;
    color: var(--color-text);
    font-size: 1.5rem;
    margin-right: 1rem;
    position: relative;
    top: 1px;
    display: grid;
    place-items: center;
}

.navText {
    font-size: 1rem;
}
