button {
    display: block;
    border: 0;
    border-radius: 2px;
    background-color: var(--color-theme-500);
    font-size: 1.2rem;
    padding: 0.25rem 0.5rem;
    color: var(--color-neutral-100);
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: var(--color-theme-700);
    }

    &:active {
        background-color: var(--color-blue-700);
        transition: none;
    }

    &:disabled {
        background-color: var(--color-neutral-700);
    }
}

.scrollingBars {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
    background-size: 50px 50px;
    animation: move 2s linear infinite;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

input[type="range"] {
    background: transparent;
    border: none;
    -webkit-appearance: none;

    &::-moz-focus-outer {
        border: 0;
    }

    &::-moz-range-thumb {
        cursor: pointer;
        background-color: var(--color-theme-500);
        border: none;
        transition: all 0.3s;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;

        &:hover {
            background-color: white;
        }
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin-top: calc(-0.5rem + 1.5px);

        cursor: pointer;
        background-color: var(--color-theme-500);
        border: none;
        transition: all 0.3s;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;

        &:hover {
            background-color: white;
        }
    }

    &::-ms-thumb {
        cursor: pointer;
        background-color: var(--color-theme-500);
        border: none;
        transition: all 0.3s;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;

        &:hover {
            background-color: white;
        }
    }

    &::-webkit-slider-runnable-track {
        height: 3px;
        cursor: pointer;
        background: var(--color-neutral-800);
        border-radius: 2px;
    }

    &:focus::-webkit-slider-runnable-track {
        background: var(--color-neutral-800);
    }

    &::-moz-range-track {
        height: 3px;
        cursor: pointer;
        background: var(--color-neutral-800);
        border-radius: 2px;
    }

    &::-ms-track {
        height: 3px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 6px 0;
        color: transparent;
    }
    &::-ms-fill-lower {
        background: var(--color-neutral-800);
        border-radius: 2px;
    }
    &:focus::-ms-fill-lower {
        background: var(--color-neutral-800);
    }
    &::-ms-fill-upper {
        background: var(--color-neutral-800);
        border-radius: 2px;
    }
    &:focus::-ms-fill-upper {
        background: var(--color-neutral-800);
    }
}
