.handyConnection {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: 1rem;
    }

    input[type="text"] {
        width: calc(100% - 2rem);
        font-size: 1rem;
        padding: 0.25rem 0.5rem;
        background-color: var(--color-neutral-400);
        margin: 0.5rem 0rem;
        margin-left: 1rem;
        text-align: center;

        &:disabled {
            color: var(--color-neutral-800);
            border: 1px solid transparent;
        }
    }

    input[type="submit"],
    button {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        font-size: 1rem;
        background-color: var(--color-theme-500);
        border: 0;
        border-radius: 2px;
        padding: 0.25rem 0.5rem;
        color: var(--color-neutral-100);
    }
}

.icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    position: relative;
    top: 1px;
}

.loading {
    color: var(--color-yellow-700);
}

.connected {
    color: var(--color-green-700);
}

.notConnected {
    color: var(--color-red-700);
}
