.auto {
    padding: 2rem;
}

.cycler {
    h2 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    button {
        margin: 0 auto;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
    }
}

.control {
    display: flex;
    align-items: center;
    height: 2.5rem;

    label {
        width: 12rem;
    }

    input[type="checkbox"] {
        transform: scale(2);
    }

    input[type="range"] {
        flex-grow: 2;
        margin-right: 1rem;
    }

    p {
        width: 4rem;
    }

    p.description {
        flex-grow: 2;
        margin-left: 1rem;
        font-style: italic;
        color: var(--color-neutral-800);
    }
}

.progressBar {
    width: 100%;
    height: 2rem;
    border: 1px solid white;
    border-radius: 2px;

    div {
        background-color: var(--color-theme-500);
        z-index: -1;
        transition: all 0.3s;
    }

    p {
        margin: 0;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
}
