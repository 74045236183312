.footer {
    padding: 0 1rem 1rem 1rem;
    background-color: var(--color-neutral-300);
    font-size: 0.9rem;

    p {
        margin: 0;
        color: var(--color-neutral-600);
    }
}
