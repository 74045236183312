//--------------------------------GLOBAL

.play {
    display: grid;
    grid-template-rows: 3fr 5fr 2fr;
    grid-auto-columns: 1fr;
    gap: 2rem;
    height: 100%;
}

//--------------------------------DROPZONES

.dropzones {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    padding-bottom: 0;
    height: 30%;
}

.dropzone {
    border: 1px dashed var(--color-neutral-700);
    // display: grid;
    // place-items: center;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.3s;
    position: relative;

    > div {
        display: grid;
        place-items: center;
        grid-template-rows: 1fr 1fr;
        padding: 2rem;
        height: 100%;
    }

    svg {
        color: var(--color-neutral-700);
        font-size: 6rem;
        transition: all 0.3s;
    }

    p {
        color: var(--color-text);
        font-size: 1.5rem;
        text-align: center;
        height: 3rem;
        margin: 0;
    }

    p.dropError {
        position: absolute;
        bottom: 0;
        font-size: 1.2rem;
        color: var(--color-red-700);
    }
}

.active {
    border: 1px dashed var(--color-text);
    background-color: rgba(255, 255, 255, 0.2);

    svg {
        color: var(--color-theme-500);
    }
}

.error {
    color: var(--color-red-700);
    font-size: 1.2rem;

    button {
        background-color: var(--color-red-700);
    }
}

//-----------------------------------PREPARING

.preparingPlayer {
    width: 100%;
    position: relative;
}

.preparing {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;

    > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    svg {
        font-size: 8rem;
        color: var(--color-theme-500);
        margin-bottom: 1rem;
    }
}

.progress {
    background-color: var(--color-neutral-100);
    width: 100%;
    height: 2rem;
    border-radius: 2px;
    position: relative;

    > div {
        position: absolute;
        left: 0px;
        height: 100%;
        background-color: var(--color-theme-500);
        transition: all 0.3s;
    }
}

//-----------------------------------PLAYER

.player {
    background-color: black;
    position: relative;
    height: 100%;

    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        overflow: hidden;
        background-size: cover;
    }
}

//----------------------------------PREVIEW

.preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

//-----------------------------------CONTROLS

.controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20%;

    .infoControls {
        display: flex;
        justify-content: space-between;
        padding: 0 2rem;
    }

    .scriptInfo {
        margin-right: 1rem;
        min-width: 200px;

        h2 {
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 1.2rem;
            margin: 0;

            span {
                font-weight: bold;
            }
        }
    }

    .syncOffset {
        display: grid;
        place-items: center;
        grid-template-columns: 1fr 2fr 1fr;

        .syncButton {
            position: relative;

            button {
                height: 4rem;
            }

            p {
                position: absolute;
                height: 2rem;
                bottom: -2rem;
                width: 100%;
                font-size: 1rem;
                text-align: center;
                left: 0;
                margin: 0;
                padding: 0;
            }
        }

        p {
            // margin: 0 2rem;
            padding: 0 1rem;
            margin: 0;
            font-size: 1.5rem;
            // width: 24rem;

            span {
                display: block;
                text-align: center;
            }
        }
    }
}

canvas {
    width: 100%;
}

.heatmap {
    position: absolute;
    bottom: 0;
}
