:root {
    --color-bg: #121212;
    --color-text: #fff;

    --color-theme-100: #af1d50;
    --color-theme-300: #dd2c6a;
    --color-theme-500: #e8739c;
    --color-theme-700: #f1a7c1;
    --color-theme-900: #f6cada;

    --color-neutral-100: #080808;
    --color-neutral-200: #121212;
    --color-neutral-300: #202020;
    --color-neutral-400: #303030;
    --color-neutral-500: #505050;
    --color-neutral-600: #707070;
    --color-neutral-700: #909090;
    --color-neutral-800: #b0b0b0;
    --color-neutral-900: #d0d0d0;

    --color-red-300: #811515;
    --color-red-400: #b61b1b;
    --color-red-500: #e51212;
    --color-red-600: #ec3434;
    --color-red-700: #f46161;

    --color-yellow-300: #cb710e;
    --color-yellow-400: #dd8a10;
    --color-yellow-500: #f69505;
    --color-yellow-600: #f3a634;
    --color-yellow-700: #f4b65b;

    --color-green-300: #3b7f16;
    --color-green-400: #47a116;
    --color-green-500: #4ec210;
    --color-green-600: #6ed536;
    --color-green-700: #88e556;

    --color-blue-300: #104190;
    --color-blue-400: #1454ba;
    --color-blue-500: #0b60e9;
    --color-blue-600: #2d75ea;
    --color-blue-700: #6099f5;

    --color-purple-300: #531072;
    --color-purple-400: #701999;
    --color-purple-500: #9821f3;
    --color-purple-600: #ae4ef7;
    --color-purple-700: #d884fe;

    --breakpoint-stacked: 1000px;
}
