.changelog {
    border-top: 1px solid var(--color-neutral-600);
    padding-left: 1rem;

    > div {
        padding: 0 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid var(--color-neutral-600);
        padding-bottom: 1rem;
    }

    h2 {
        margin: 1rem 0 0 0;
    }
    h3 {
        margin: 0 0 0.5rem 0;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--color-neutral-800);
        font-weight: normal;
    }
    h4 {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
    }
    ul {
        margin-bottom: 1rem;
        list-style: disc;
        padding-left: 1.5rem;
    }

    li {
        margin-bottom: 0.25rem;
    }

    hr {
        margin: 2rem 0 2rem 0;
    }
}
